<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Kategori </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputKategori">
            <CRow>
              <CCol sm="12">
              <CInput
                v-model="nama_kategori"
                label="Nama Kategori"
                placeholder="Input Nama Kategori"
              />
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label>Jenis Data</label>
                  <select v-model="jenis_data" placeholder="-- Pilih Jenis Data --" class="form-control">
                      <option value="" disabled selected>-- Pilih Jenis Data --</option>
                      <option value="spasial">Spasial</option>
                      <option value="dokumen">Dokumen</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Parent</label>
                  <select class="form-control" v-model="id_parent">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="0">N/A</option>
                    <option v-for="r in kategoris" :value="r.id_kategori">{{r.nama_kategori}} | {{ r.jenis_data }} </option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="3">
                <div class="form-group">
                  <label>Status Aktif</label>
                  <select v-model="status_aktif_kategori" placeholder="-- Pilih Status --" class="form-control">
                      <option value="" disabled selected>-- Pilih Status --</option>
                      <option value="1">Aktif</option>
                      <option value="0">Tidak Aktif</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Level</label>
                  <select class="form-control" v-model="level">
                    <option value="1">Level 1</option>
                    <option value="2">Level 2</option>
                    <option value="3">Level 3</option>
                    <option value="4">Level 4</option>
                    <option value="5">Level 5</option>
                    <option value="6">Level 6</option>
                    <option value="7">Level 7</option>
                  </select>
                </div>
              </CCol> 
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Foto Kategori</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol> -->
            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/kategori">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      nama_kategori : "",
      jenis_data : "",
      level: "1",
      id_parent : "",
      status_aktif_kategori : "",
      placeholder : "Pilih File",
      selected: [], // Must be an array reference!
      kategoris: [],
      show: true,
    }
  },
  mounted(){
  this.loadData();
  },
  
  methods: {

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"kategoris")
        .then((response) => {
              this.kategoris=response.data;
        });
    },

    inputKategori: function(event){

      const formData = new FormData();
        
        formData.append('nama_kategori', this.nama_kategori)
        formData.append('jenis_data', this.jenis_data)
        formData.append('id_parent', this.id_parent)
        formData.append('level', this.level)
        formData.append('status_aktif_kategori', this.status_aktif_kategori)
      
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"kategori", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/kategori');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>